import {
  APP_INITIALIZER,
  enableProdMode,
  ErrorHandler,
  importProvidersFrom,
} from '@angular/core';
import { environment } from './environments/environment';
import './sentry';
import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { provideHttpClient } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MatDialogModule } from '@angular/material/dialog';
import { GraphQLModule } from './app/graph-ql.module';
import { provideAnimations } from '@angular/platform-browser/animations';
import { AUTH_FACTORY, AuthProvider } from './auth';
import { provideNgxMask } from 'ngx-mask';
import { createErrorHandler } from '@sentry/angular-ivy';
import { provideRouter } from '@angular/router';
import { APP_ROUTES } from './app/app-routing.module';

const sentry =
  environment.name !== 'development'
    ? [
        {
          provide: ErrorHandler,
          useValue: createErrorHandler({
            showDialog: false,
          }),
        },
      ]
    : [];

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      MatDialogModule,
      GraphQLModule,
      //TODO: Switch to provider when we upgrade to Angular v16
      ServiceWorkerModule.register('ngsw-worker.js', {
        enabled: environment.serviceWorker,
        // Register the ServiceWorker as soon as the app is stable
        // or after 30 seconds (whichever comes first).
        registrationStrategy: 'registerWhenStable:30000',
      }),
    ),
    provideAnimations(),
    provideHttpClient(),
    provideRouter(APP_ROUTES),
    AuthProvider,
    {
      provide: APP_INITIALIZER,
      useFactory: AUTH_FACTORY,
      deps: [AuthProvider],
      multi: true,
    },
    ...sentry,
    provideNgxMask(),
  ],
}).catch((err) => console.error(err));
