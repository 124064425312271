import { Injectable, OnDestroy } from '@angular/core';
import { fetchAuthSession } from '@aws-amplify/auth';
import { BehaviorSubject, from, of, Subscription } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SystemSettingsRestService } from '../feature/system-settings/services/system-settings-rest.service';

@Injectable({
  providedIn: 'root',
})
export class HeaderStateService implements OnDestroy {
  session$ = from(fetchAuthSession()).pipe(
    map((s) => Boolean(s.tokens)),
    catchError((err) => {
      console.log(err);
      return of(false);
    }),
  );
  isLoggedIn = new BehaviorSubject(false);
  icon = new BehaviorSubject<string | null>(null);
  icon$ = this.icon.asObservable();
  private subscription: Subscription | null = null;

  constructor(private systemSettingsRestService: SystemSettingsRestService) {
    this.session$.subscribe((valid) => {
      if (valid) {
        this.isLoggedIn.next(true);
      }
    });
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  setIsLoggedIn(value: boolean) {
    this.isLoggedIn.next(value);
  }

  updateIcon() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.subscription = this.systemSettingsRestService
      .getTenantLogo()
      .subscribe({
        next: (v) => {
          this.icon.next(v);
        },
        error: () => {
          this.icon.next(null);
        },
      });
  }
}
