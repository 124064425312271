import { inject } from '@angular/core';
import { Router } from '@angular/router';

import { fetchAuthSession } from '@aws-amplify/auth';
import { HeaderStateService } from '../services/header-state.service';

export const authenticatedGuard = async () => {
  const router = inject(Router);
  const headerState = inject(HeaderStateService);
  try {
    const session = await fetchAuthSession();
    if (session.tokens) {
      return true;
    }
  } catch {}
  headerState.setIsLoggedIn(false);
  return router.parseUrl('/login');
};
