<ng-container *ngrxLet="state$ as state">
  <app-header *ngIf="state.isLoggedIn"></app-header>
  <mat-drawer-container
    [ngStyle]="{
      height: state.isLoggedIn ? 'calc(100vh - 65px)' : '100vh',
    }"
    [hasBackdrop]="true"
    (backdropClick)="onMenuClicked()"
  >
    <mat-drawer
      mode="over"
      class="drawer"
      position="end"
      disableClose
      [opened]="state.menuState"
    >
      <app-main-nav-menu
        *ngIf="state.isLoggedIn && state.tenantID"
      ></app-main-nav-menu>
    </mat-drawer>
    <mat-drawer-content>
      <div *ngIf="state.isLoggedIn === false" class="elegant-logo-container">
        <img src="assets/elegant-logo.png" alt="elegant-logo" height="120px" />
      </div>
      <router-outlet></router-outlet>
    </mat-drawer-content>
  </mat-drawer-container>
</ng-container>
