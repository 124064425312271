import { Component, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { SpinnerComponent } from '../spinner/spinner.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { LetDirective } from '@ngrx/component';
import { NgClass, NgForOf, NgIf } from '@angular/common';

export type ConfirmDialogData =
  | string
  | {
      message: string;
      confirmLabel?: string;
      cancelLabel?: string;
      title?: string;
      operation?: Observable<any>;
      itemList?: string[];
    };

@Component({
  standalone: true,
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
  imports: [
    LetDirective,
    MatButtonModule,
    MatDialogModule,
    NgClass,
    NgForOf,
    NgIf,
    SpinnerComponent,
  ],
})
export class ConfirmationDialogComponent implements OnDestroy {
  readonly inProgress$ = new BehaviorSubject(false);
  readonly title = typeof this.data === 'object' && this.data.title;
  readonly message =
    typeof this.data === 'string' ? this.data : this.data.message;
  readonly cancelLabel =
    (typeof this.data === 'object' && this.data.cancelLabel) || 'cancel';
  readonly confirmLabel =
    (typeof this.data === 'object' && this.data.confirmLabel) || 'confirm';
  readonly itemList = typeof this.data === 'object' && this.data.itemList;

  private readonly operation$ =
    typeof this.data === 'object' && this.data.operation;
  private subscription?: Subscription;

  constructor(
    private dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: ConfirmDialogData,
  ) {}

  /**
   * Easily determines if the user confirmed the action.
   *
   * @param data
   * @returns
   */
  static confirmed(data: any): boolean {
    return data?.data === 'confirmed';
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  onConfirmClicked() {
    if (!this.operation$) {
      this.dialogRef.close({
        data: 'confirmed',
      });
      return;
    }
    this.inProgress$.next(true);
    this.subscription = this.operation$.subscribe({
      next: () => this.dialogRef.close({ data: 'confirmed' }),
      error: () => this.dialogRef.close(),
      complete: () => this.dialogRef.close(),
    });
  }
}
