import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';

@Component({
  standalone: true,
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
  imports: [NgIf, MatButtonModule],
})
export class ErrorComponent {
  @Input() refreshCurrentPage?: boolean;
  @Input() customError?: string;
  previousUrl = window.location.pathname?.split('/error-page/')[1];

  constructor(public router: Router) {}

  refresh() {
    if (this.refreshCurrentPage) {
      window.location.reload();
    } else {
      this.router.navigateByUrl(this.previousUrl);
    }
  }
}
