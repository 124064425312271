import { Component } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { combineLatestWith, first, map, mergeMap } from 'rxjs/operators';

import { AppQueryService } from 'src/app/services/app-query.service';
import { CurrentRouteService } from '../services/current-route.service';
import { MainMenuStateService } from '../services/main-menu-state.service';
import { SwitchTenantsService } from '../services/switch-tenants.service';
import { menuItemsList } from './menu-items';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatListModule } from '@angular/material/list';
import { NgFor } from '@angular/common';
import { LetDirective } from '@ngrx/component';

@Component({
  standalone: true,
  selector: 'app-main-nav-menu',
  templateUrl: './main-nav-menu.component.html',
  styleUrls: ['./main-nav-menu.component.scss'],
  imports: [
    FontAwesomeModule,
    MatListModule,
    LetDirective,
    NgFor,
    RouterModule,
  ],
})
export class MainNavMenuComponent {
  currentRoute$ = this.currentRouteService.currentRoute$.pipe(
    map((r) => r.split('/')[0]),
  );
  menuItemsAndRoute$ = this.switchTenants.switchTenants$.pipe(
    first(),
    map((hideSwitchTenants) =>
      hideSwitchTenants
        ? menuItemsList.filter((i) => i.id !== 'switch-tenants')
        : menuItemsList,
    ),
    mergeMap((menuItems) =>
      this.appQueryService
        .viewerUser()
        .valueChanges.pipe(
          map((viewer) =>
            menuItems.filter(
              (m) =>
                m.include?.(
                  viewer.data.viewerUser.userRole.permissions ?? [],
                ) ?? true,
            ),
          ),
        ),
    ),
    combineLatestWith(this.currentRoute$),
    map(([menuItems, currentRoute]) => ({ menuItems, currentRoute })),
  );

  constructor(
    private mainMenuStateService: MainMenuStateService,
    public router: Router,
    private switchTenants: SwitchTenantsService,
    private currentRouteService: CurrentRouteService,
    private appQueryService: AppQueryService,
  ) {}

  onMenuItemClicked() {
    this.mainMenuStateService.toggleMainMenuState();
  }
}
