import { Component, Input } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgClass } from '@angular/common';

@Component({
  standalone: true,
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  imports: [MatProgressSpinnerModule, NgClass],
})
export class SpinnerComponent {
  @Input() overlay = false;
  @Input() color: ThemePalette = 'primary';
  constructor() {
    // do nothing.
  }
}
