import { Component } from '@angular/core';
import {
  faArrowUpRightFromSquare,
  faEnvelope,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatButtonModule } from '@angular/material/button';

@Component({
  standalone: true,
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
  imports: [FontAwesomeModule, MatButtonModule],
})
export class HelpComponent {
  readonly faArrowUpRightFromSquare = faArrowUpRightFromSquare;
  readonly faEnvelope = faEnvelope;
  constructor() {
    // do nothing.
  }
}
