import { Component } from '@angular/core';
import { combineLatest, map } from 'rxjs';
import { CheckForUpdateService } from './services/check-for-update.service';
import { HeaderStateService } from './services/header-state.service';

import { MainMenuStateService } from './services/main-menu-state.service';
import { TenantIDService } from './services/tenant-id.service';
import { NgIf, NgStyle } from '@angular/common';
import { LetDirective } from '@ngrx/component';
import { HeaderComponent } from './header/header.component';
import { MainNavMenuComponent } from './main-nav-menu/main-nav-menu.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterOutlet } from '@angular/router';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';

@Component({
  standalone: true,
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [
    LetDirective,
    HeaderComponent,
    MainNavMenuComponent,
    MatSidenavModule,
    NgStyle,
    NgIf,
    RouterOutlet,
  ],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
})
export class AppComponent {
  readonly title = 'Elegant';
  readonly state$ = combineLatest([
    this.mainMenuStateService.menuState$,
    this.headerStateService.isLoggedIn,
    this.tenantIDService.tenantID$,
  ]).pipe(
    map(([menuState, isLoggedIn, tenantID]) => ({
      menuState,
      isLoggedIn,
      tenantID,
    })),
  );

  constructor(
    private mainMenuStateService: MainMenuStateService,
    private headerStateService: HeaderStateService,
    private tenantIDService: TenantIDService,
    private _checkForUpdateService: CheckForUpdateService,
  ) {}

  onMenuClicked() {
    this.mainMenuStateService.toggleMainMenuState();
  }
}
