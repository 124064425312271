import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ConfirmationDialogComponent,
  ConfirmDialogData,
} from './confirmation-dialog.component';

/**
 * Easily open an operation confirmation dialog popup that upon success will execute the intended operation. This allows
 * the dialog to show the operation is in progress before exiting allow the calling code to remove the burden of showing
 * operation progress.
 *
 */
@Injectable({
  providedIn: 'root',
})
export class ConfirmationDialogService {
  constructor(private dialog: MatDialog) {}

  confirm(confirmOptions: ConfirmDialogData, width?: string) {
    return this.dialog.open(ConfirmationDialogComponent, {
      data: confirmOptions,
      width,
    });
  }
}
