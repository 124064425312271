import { NgStyle } from '@angular/common';
import { Component, ElementRef, Input, AfterContentInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faLongArrowRight } from '@fortawesome/pro-regular-svg-icons';

@Component({
  standalone: true,
  selector: 'app-home-card',
  templateUrl: './home-card.component.html',
  styleUrls: ['./home-card.component.scss'],
  imports: [RouterModule, NgStyle, FontAwesomeModule],
})
export class HomeCardComponent implements AfterContentInit {
  @Input() backgroundImage: string;
  @Input() backgroundColor: string;
  @Input() textShadow: string;
  @Input() title: string;
  @Input() path: string;
  backgroundPosition = 'center center';
  backgroundSize = '250px 350px';
  faLongArrowRight = faLongArrowRight;

  constructor(private elRef: ElementRef) {}

  ngAfterContentInit() {
    this.elRef.nativeElement.style.setProperty(
      '--background',
      this.backgroundColor,
    );
    this.elRef.nativeElement.style.setProperty(
      '--text-shadow',
      `1px 4px 4px ${this.textShadow}`,
    );
  }
}
