import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MainMenuStateService {
  mainMenuState = new BehaviorSubject(false);

  /* eslint-disable @typescript-eslint/member-ordering */
  readonly menuState$ = this.mainMenuState.asObservable();

  constructor() {
    // do nothing.
  }

  toggleMainMenuState() {
    this.mainMenuState.next(!this.mainMenuState.value);
  }
}
