<mat-selection-list
  [multiple]="false"
  [hideSingleSelectionIndicator]="true"
  *ngrxLet="menuItemsAndRoute$ as menuItemsAndRoute"
>
  <mat-list-option
    class="menu-item-list"
    *ngFor="let item of menuItemsAndRoute.menuItems"
    [selected]="menuItemsAndRoute.currentRoute === item.route"
    (click)="onMenuItemClicked()"
  >
    <a class="menu-item" [routerLink]="item.route">
      <fa-icon size="lg" [icon]="item.icon" class="accent-icon"></fa-icon>
      <h3 class="mat-h3 primary-typography" color="primary">
        {{ item.name }}
      </h3>
    </a>
  </mat-list-option>
</mat-selection-list>
