import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { TenantIDService } from '../../services/tenant-id.service';
import { Apollo } from 'apollo-angular';
import { HeaderStateService } from '../../services/header-state.service';
import {
  SystemSettingsRestService,
  TenantWithLogo,
} from '../system-settings/services/system-settings-rest.service';
import { ErrorComponent } from '../shared/error/error.component';
import { SpinnerComponent } from '../shared/spinner/spinner.component';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';

@Component({
  standalone: true,
  selector: 'app-switch-tenants',
  templateUrl: './switch-tenants.component.html',
  styleUrls: ['./switch-tenants.component.scss'],
  imports: [ErrorComponent, SpinnerComponent, AsyncPipe, NgIf, NgFor],
})
export class SwitchTenantsComponent {
  error: string;
  tenants$ = this.systemSettingsRestService.getTenantsLogos();

  constructor(
    public router: Router,
    private tenantIDService: TenantIDService,
    private systemSettingsRestService: SystemSettingsRestService,
    private apollo: Apollo,
    private headerStateService: HeaderStateService,
  ) {}

  async onTenantSelected(t: TenantWithLogo | null | undefined) {
    if (t) {
      this.tenantIDService.selectTenant(t.ID);
      await this.apollo.client.resetStore();
      this.headerStateService.updateIcon();
      this.router.navigate(['home']);
    }
  }
}
