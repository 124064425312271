import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppQueryService } from './app-query.service';
import { TenantIDService } from './tenant-id.service';

@Injectable({
  providedIn: 'root',
})
export class SwitchTenantsService {
  switchTenants$: Observable<boolean>;

  constructor(
    private appQueryService: AppQueryService,
    private tenantIDService: TenantIDService,
  ) {
    this.switchTenants$ = this.appQueryService.viewer().valueChanges.pipe(
      map((result) => {
        const { viewer } = result.data;
        const switchTenants = viewer?.length === 1;
        if (switchTenants) {
          viewer?.map((t) => this.tenantIDService.selectTenant(t?.id || ''));
        }
        return switchTenants;
      }),
    );
  }
}
