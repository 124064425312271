import gql from 'graphql-tag';

export const VIEWER_USER_QUERY = gql`
  query viewerUser {
    viewerUser {
      id
      givenName
      familyName
      email
      preferences {
        date
        currency
        units
      }
      userRole {
        id
        name
        permissions
      }
    }
  }
`;

export const VIEWER_USER_SUMMARY_QUERY = gql`
  query viewerUserSummary {
    viewerUser {
      id
      givenName
      familyName
      email
    }
  }
`;
