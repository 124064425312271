<ng-template #loading>
  <ng-template [ngIf]="!error"><app-spinner></app-spinner></ng-template>
  <app-error *ngIf="error" [refreshCurrentPage]="true"> </app-error>
</ng-template>
<div class="content" *ngIf="tenants$ | async as tenants; else loading">
  <h1 class="mat-h1 primary-typography" color="primary">Select Tenant</h1>
  <div *ngIf="error">
    <app-error></app-error>
  </div>
  <div class="tenants-container">
    <div
      class="mat-h4 primary-typography tenant-item"
      *ngFor="let item of tenants"
      (click)="onTenantSelected(item)"
    >
      <div class="icon-container">
        <img
          class="elegant-logo"
          src="assets/elegant-icon.png"
          alt="elegant-logo"
          width="20px"
        />
        <h2>{{ item?.Name }}</h2>
      </div>
      <img
        class="logo"
        *ngIf="item.Logo"
        src="{{ item.Logo }}"
        height="39px"
        alt="company-logo"
      />
      <img
        *ngIf="!item.Logo"
        class="logo"
        src="assets/elegant-logo-no-tagline.png"
        alt="logo"
        height="39px"
      />
    </div>
  </div>
</div>
