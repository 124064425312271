import { Routes } from '@angular/router';
import { UserPermission } from 'src/generated/graphql';
import { HomeComponent } from './feature/home/home.component';
import { ErrorComponent } from './feature/shared/error/error.component';
import { SwitchTenantsComponent } from './feature/switch-tenants/switch-tenants.component';
import { authenticatedGuard } from './guards/authenticated.guard';
import { PermissionGuard } from './guards/permission.guard';
import { HelpComponent } from './feature/help/help.component';

export const APP_ROUTES: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./feature/profile/profile-routing').then((m) => m.PROFILE_ROUTES),
  },
  {
    path: 'switch-tenants',
    component: SwitchTenantsComponent,
    canActivate: [authenticatedGuard],
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [authenticatedGuard],
  },
  {
    path: 'help',
    component: HelpComponent,
  },
  {
    path: 'error-page/:previousRoute',
    component: ErrorComponent,
    pathMatch: 'prefix',
    canActivate: [authenticatedGuard],
    data: {
      title: 'Error Page',
    },
  },
  {
    path: 'roles-and-users',
    loadComponent: () =>
      import('./feature/roles-and-users/roles-and-users.component').then(
        (m) => m.RolesAndUsersComponent,
      ),
    canActivate: [authenticatedGuard, PermissionGuard],
    data: {
      permissions: [UserPermission.ManageRolesAndUsers],
    },
  },
  {
    path: 'manage-plans',
    loadChildren: () =>
      import('./feature/plans/plans-routes').then((m) => m.PLAN_ROUTES),
    canActivate: [authenticatedGuard, PermissionGuard],
    data: {
      permissions: [UserPermission.ManagePlans],
      title: 'Plans',
    },
  },
  {
    path: 'manage-system',
    loadChildren: () =>
      import('./feature/system-settings/system-settings-routes').then(
        (m) => m.SYSTEM_SETTINGS_ROUTES,
      ),
    canActivate: [authenticatedGuard, PermissionGuard],
    data: {
      permissions: [UserPermission.ManageSystemSettings],
    },
  },
  {
    path: '**',
    component: ErrorComponent,
    data: {
      title: 'Error Page',
    },
  },
];
