import { NgModule } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpLink } from 'apollo-angular/http';
import { fetchAuthSession } from '@aws-amplify/auth';
import { setContext } from '@apollo/client/link/context';
import { ApolloLink, InMemoryCache } from '@apollo/client/core';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';

const uri = environment.serverUrl + '/query';

export const createApollo = (httpLink: HttpLink) => {
  const basic = setContext(() => ({
    headers: {
      accept: 'charset=utf-8',
    },
  }));

  const auth = setContext(async () => {
    try {
      const session = await fetchAuthSession();

      const token = session.tokens?.accessToken;
      if (!token) {
        return;
      }
      return {
        headers: {
          authorization: `Bearer ${token.toString()}`,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'x-tenant-id': !window.localStorage.getItem('tenantID')
            ? ''
            : window.localStorage.getItem('tenantID'),
        },
      };
    } catch (err: any) {
      if (err === 'No current user') {
        return {};
      }
      if (err.message && err.message.includes('Refresh Token has expired')) {
        window.location.assign('/login');
        return {};
      }
      throw err;
    }
  });

  /* eslint-disable @typescript-eslint/naming-convention*/
  const link = ApolloLink.from([basic, auth, httpLink.create({ uri }) as any]);
  const cache = new InMemoryCache({
    resultCaching: true,
    typePolicies: {
      Plan: {
        fields: {
          workflowHistories: {
            merge: false,
          },
        },
      },
      Query: {
        fields: {
          plan: {
            merge: false,
          },
          statusCodes: {
            merge: false,
          },
          maritalStatuses: {
            merge: false,
          },
          benefitRules: {
            merge: false,
          },
          benefitTypes: {
            merge: false,
          },
          contributionTypes: {
            merge: false,
          },
          regulatoryRules: {
            merge: false,
          },
          salaryTypes: {
            merge: false,
          },
          funds: {
            merge: false,
          },
          calculationInstructions: {
            merge: false,
          },
          interestRates: {
            merge: false,
          },
          planUnitTransactions: {
            merge: false,
          },
          member: {
            merge: false,
          },
          memberUnitTransactions: {
            merge: false,
          },
          memberSalaries: {
            merge: false,
          },
          memberBeneficiaries: {
            merge: false,
          },
          memberPensions: {
            merge: false,
          },
          memberRefunds: {
            merge: false,
          },
          memberContributions: {
            merge: false,
          },
          memberDocuments: {
            merge: false,
          },
          memberInvestmentInstructions: {
            merge: false,
          },
          states: {
            merge: false,
          },
          memberStatusHistory: {
            merge: false,
          },
          plans: {
            keyArgs: false,
            merge: (
              existing: { nodes: any },
              incoming: { nodes: any },
              { args }: any,
            ) =>
              !existing || !args?.after
                ? incoming
                : {
                    ...incoming,
                    nodes: [...existing.nodes, ...incoming.nodes],
                  },
          },
          workflowHistories: {
            merge: false,
          },
        },
      },
    },
  });
  const defaultOptions = {
    watchQuery: {
      errorPolicy: 'all',
    },
  };
  return {
    link,
    cache,
    defaultOptions,
  };
};

@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule {}
