import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';

import { faLongArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { AppQueryService } from 'src/app/services/app-query.service';
import { getQueryResult } from '../shared/utilities';
import { UserPermission } from 'src/generated/graphql';
import { AsyncPipe, NgIf } from '@angular/common';
import { HomeCardComponent } from './home-card/home-card.component';
import { ErrorComponent } from '../shared/error/error.component';
import { SpinnerComponent } from '../shared/spinner/spinner.component';

@Component({
  standalone: true,
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  imports: [
    ErrorComponent,
    SpinnerComponent,
    NgIf,
    AsyncPipe,
    HomeCardComponent,
  ],
})
export class HomeComponent {
  readonly userPermissions = UserPermission;
  faLongArrowRight = faLongArrowRight;
  queryError: string;

  permissions$ = this.appQueryService.viewerUser().valueChanges.pipe(
    getQueryResult(),
    map(({ result, error }) => {
      this.queryError = error;
      return result?.data?.viewerUser.userRole.permissions;
    }),
  );

  constructor(
    public router: Router,
    private appQueryService: AppQueryService,
  ) {}
}
