/* eslint-disable @typescript-eslint/naming-convention */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { tap } from 'rxjs/operators';
import { Amplify } from 'aws-amplify';

const region = 'ca-central-1';

interface AWSResources {
  userPoolID: string;
  userPoolClientID: string;
}
@Injectable()
export class AuthProvider {
  constructor(private http: HttpClient) {}

  initAuth() {
    return this.http
      .get<AWSResources>(environment.serverUrl + '/rest/app-config/user-pool')
      .pipe(
        tap(({ userPoolID, userPoolClientID }) => {
          Amplify.configure({
            Auth: {
              Cognito: {
                userPoolId: userPoolID,
                userPoolClientId: userPoolClientID,
              },
            },
          });
        }),
      );
  }
}

export const AUTH_FACTORY = (provider: AuthProvider) => () =>
  provider.initAuth();
