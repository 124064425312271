<a
  class="card"
  [ngStyle]="{
    background: backgroundImage,
    'background-size': backgroundSize,
    'background-color': backgroundColor,
    'background-position': backgroundPosition,
  }"
  [routerLink]="path"
>
  <h1 class="mat-h1 primary-typography">
    {{ title }}
  </h1>
  <fa-icon size="2x" [icon]="faLongArrowRight"></fa-icon>
</a>
