import { TitleCasePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
  ActivatedRoute,
  GuardsCheckStart,
  NavigationEnd,
  Router,
} from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

/**
 * RoutePath is a helper class to provide proper typing when working with a route path as an array
 */
export class RoutePath {
  private readonly pathParts: string[];

  constructor(readonly path: string) {
    this.pathParts = path.split('/');
  }

  /**
   * returns the part of the path at the position provided or undefined if out of bounds
   *
   * @param pos
   */
  get(pos: number) {
    return pos < this.pathParts.length ? this.pathParts[pos] : undefined;
  }

  /**
   * Wrapper for path.includes to simplify code using the class
   *
   * @param searchString
   * @param pos
   */
  includes(searchString: string, pos?: number) {
    return this.path.includes(searchString, pos);
  }
}

@Injectable({
  providedIn: 'root',
})
export class CurrentRouteService {
  // currentRoute is private to prevent misuse outside
  // the service.
  private currentRoute = new BehaviorSubject('');

  /**
   * Subject that provides the current and future resolved
   * routes from the router.
   */
  // eslint-disable-next-line @typescript-eslint/member-ordering
  currentRoute$ = this.currentRoute.asObservable();

  /**
   * Takes the currentRoute$ and maps the value into a RoutePath class
   */
  // eslint-disable-next-line @typescript-eslint/member-ordering
  currentRoutePath$ = this.currentRoute$.pipe(
    map((path) => new RoutePath(path)),
  );
  /**
   * Subject that provides the current planID based on the current
   * route.
   *
   * If the route does not provide the planID in an expected way the
   * observable will return a blank string.
   */
  /* eslint-disable @typescript-eslint/member-ordering */
  currentPlanID$ = this.currentRoute$.pipe(
    map((route) => {
      const parts = route.split('/');
      // path should be manage-plans/{planID}/rest/of/route
      if (parts[0] === 'manage-plans') {
        return parts[1] ?? '';
      }
      return '';
    }),
  );

  /**
   * Subject that provides the current system setting route based on the current
   * route.
   */
  /* eslint-disable @typescript-eslint/member-ordering */
  currentSystemSettingsRoute$ = this.currentRoute$.pipe(
    map((route) => {
      const parts = route.split('/');
      // path should be manage-system/rest/of/route
      return parts[1];
    }),
  );

  /**
   * Subject that provides the current memberID based on the current
   * route.
   *
   * If the route does not provide the memberID in an expected way the
   * observable will return a blank string.
   */
  currentMemberID$ = this.currentRoute$.pipe(
    map((route) => {
      const parts = route.split('/');
      // path should be manage-plans/{planID}/rest/of/route
      if (
        parts[0].includes('manage-plans') &&
        parts[2] === 'members' &&
        parts.length > 3
      ) {
        return parts[3];
      }
      return '';
    }),
  );

  /**
   * Subject that provides the current planID and memberID based on the current
   * route.
   *
   * If the route does not provide the planID and memberID in an expected way the
   * observable will return blank strings.
   */
  currentMemberAndPlanIDs$ = this.currentRoute$.pipe(
    map((route) => {
      let planID = '';
      let memberID = '';
      const parts = route.split('/');
      // path should be manage-plans/{planID}/rest/of/route
      if (parts[0] === 'manage-plans') {
        planID = parts[1];
      }
      if (parts[2] === 'members' && parts.length > 3) {
        memberID = parts[3];
      }
      return { planID, memberID };
    }),
  );

  constructor(
    private router: Router,
    private titleService: Title,
  ) {
    this.router.events
      .pipe(
        filter(
          (e) => e instanceof NavigationEnd || e instanceof GuardsCheckStart,
        ),
        tap((e) => {
          if (e instanceof NavigationEnd) {
            const url = (e as NavigationEnd).urlAfterRedirects.substring(1);
            const title = url?.split('/')?.pop();
            let tabTitle = 'Elegant';

            let route: ActivatedRoute = this.router.routerState.root;

            while (route.firstChild) {
              route = route.firstChild;
            }

            if (route.snapshot.data.title) {
              tabTitle = route.snapshot.data.title;
            } else if (title) {
              tabTitle = title.replace(/-/g, ' ');
            }
            this.titleService.setTitle(
              new TitleCasePipe().transform(tabTitle) + ' - Elegant',
            );
          }
        }),
        map((e) => {
          if (e instanceof NavigationEnd) {
            return (e as NavigationEnd).urlAfterRedirects.substring(1);
          } else {
            return (e as GuardsCheckStart).url.substring(1);
          }
        }),
      )
      .subscribe(this.currentRoute);
  }
}
