import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { map, tap } from 'rxjs/operators';
import { UserPermission, ViewerUserQuery } from 'src/generated/graphql';
import { getQueryResult } from '../feature/shared/utilities';
import { AppQueryService } from '../services/app-query.service';

@Injectable({
  providedIn: 'root',
})
export class PermissionGuard {
  constructor(
    private router: Router,
    private appQueryService: AppQueryService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot) {
    const permissions = route.data.permissions as Array<UserPermission>;

    return this.appQueryService.viewerUser().valueChanges.pipe(
      getQueryResult(),
      map(({ result, error }) => {
        if (error) {
          console.log(error);
        }
        return !!(
          result?.data as ViewerUserQuery
        )?.viewerUser.userRole.permissions.find((p) => permissions.includes(p));
      }),
      tap((valid) => {
        if (!valid) {
          this.router.navigateByUrl('home');
        }
      }),
    );
  }
}
