import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { updateSentryScope } from 'src/sentry';

@Injectable({
  providedIn: 'root',
})
export class TenantIDService {
  private tenantID = new BehaviorSubject(this.getID() || '');

  /* eslint-disable @typescript-eslint/member-ordering */
  readonly tenantID$ = this.tenantID.asObservable();

  private clearID() {
    try {
      localStorage.removeItem('tenantID');
    } catch (err) {
      console.log('local storage delete:', err);
    }
  }

  private getID() {
    try {
      return localStorage.getItem('tenantID');
    } catch (err) {
      console.log('local storage get:', err);
    }
    return null;
  }

  private setID(newID: string) {
    try {
      return localStorage.setItem('tenantID', newID);
    } catch (err) {
      console.log('local storage set:', err);
    }
  }

  selectTenant(tenantID: string) {
    this.setID(tenantID);
    updateSentryScope(null, tenantID);
    this.tenantID.next(tenantID);
  }

  clearTenant() {
    this.clearID();
    this.tenantID.next('');
  }
}
