import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';

import { ViewerQuery, ViewerUserQuery } from 'src/generated/graphql';
import { VIEWER_QUERY } from '../feature/switch-tenants/query';
import { VIEWER_USER_QUERY } from './query';

@Injectable({
  providedIn: 'root',
})
export class AppQueryService {
  constructor(private apollo: Apollo) {}

  viewer() {
    return this.apollo.watchQuery<ViewerQuery>({
      query: VIEWER_QUERY,
      fetchPolicy: 'network-only',
    });
  }

  viewerUser() {
    return this.apollo.watchQuery<ViewerUserQuery>({
      query: VIEWER_USER_QUERY,
      fetchPolicy: 'network-only',
    });
  }
}
