<div>
  <h2 class="mat-h2 primary-typography" color="primary" *ngIf="!customError">
    Something went wrong ...!!!
  </h2>
  <h4 class="mat-h4 primary-typography" color="primary" *ngIf="!customError">
    Please refresh the page. Please contact the system administrator if issue
    persists.
  </h4>
  <h4 class="mat-h4 primary-typography" color="primary" *ngIf="customError">
    {{ customError }}
  </h4>
  <button mat-stroked-button color="accent" (click)="refresh()">REFRESH</button>
</div>
