import gql from 'graphql-tag';

export const VIEWER_QUERY = gql`
  query Viewer {
    viewer {
      id
      name
    }
  }
`;
