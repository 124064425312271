/* eslint-disable @typescript-eslint/naming-convention */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CognitoApiService } from '../../../services/cognito-api.service';
import { map, mergeMap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { TenantIDService } from 'src/app/services/tenant-id.service';
import { combineLatest, EMPTY } from 'rxjs';
export interface TenantWithLogo {
  ID: string;
  Name: string;
  Logo: string;
}
@Injectable({
  providedIn: 'root',
})
export class SystemSettingsRestService {
  constructor(
    private http: HttpClient,
    private cognitoApiService: CognitoApiService,
    private tenantIDService: TenantIDService,
  ) {}

  uploadTenantLogo(formData: FormData) {
    return this.cognitoApiService.accessToken().pipe(
      mergeMap((token) =>
        this.http.post(environment.serverUrl + '/rest/logos', formData, {
          headers: {
            authorization: `Bearer ${token?.toString()}`,
            'x-tenant-id': window.localStorage.getItem('tenantID') ?? '',
          },
          responseType: 'text',
        }),
      ),
    );
  }

  getTenantLogo() {
    return combineLatest([
      this.cognitoApiService.accessToken(),
      this.tenantIDService.tenantID$,
    ]).pipe(
      mergeMap(([token, tenantID]) => {
        if (!tenantID) {
          return EMPTY;
        }
        return this.http.get(environment.serverUrl + '/rest/logos', {
          headers: {
            authorization: `Bearer ${token?.toString()}`,
            'x-tenant-id': tenantID,
          },
          responseType: 'text',
        });
      }),
    );
  }

  getTenantsLogos() {
    return this.cognitoApiService.accessToken().pipe(
      mergeMap((token) =>
        this.http.get<TenantWithLogo[]>(
          environment.serverUrl + '/rest/logos/all',
          {
            headers: {
              authorization: `Bearer ${token?.toString()}`,
            },
            responseType: 'json',
          },
        ),
      ),
    );
  }
}
