import { ApplicationRef, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SwUpdate } from '@angular/service-worker';
import { concat, interval } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { captureException } from '@sentry/angular-ivy';
import { environment } from '../../environments/environment';
import { ConfirmationDialogService } from '../feature/shared/confirmation-dialog/confirmation-dialog.service';

const hour = 3600000;

@Injectable({ providedIn: 'root' })
export class CheckForUpdateService {
  constructor(
    appRef: ApplicationRef,
    private updates: SwUpdate,
    private dialog: MatDialog,
    private confirmDialogService: ConfirmationDialogService,
  ) {
    // Skip the checking if in development
    if (environment.name === 'development') {
      return;
    }
    // Allow the app to stabilize first, before starting polling for updates with `interval()`.
    const appIsStable$ = appRef.isStable.pipe(first((isStable) => isStable));
    const everyHours$ = interval(hour);
    const everyHoursOnceAppIsStable$ = concat(appIsStable$, everyHours$);

    everyHoursOnceAppIsStable$.subscribe(() => {
      this.updates.checkForUpdate().catch((err) => console.error(err));
    });

    this.updates.versionUpdates.subscribe((v) => {
      if (v.type === 'VERSION_INSTALLATION_FAILED') {
        captureException(v.error);
        return;
      }
      if (v.type === 'VERSION_READY') {
        this.openDialog();
      }
    });
  }

  private openDialog() {
    const dialogRef = this.confirmDialogService.confirm({
      title: 'New version of Elegant Available',
      message: 'Update now by refreshing or dismiss and update later.',
      confirmLabel: 'Refresh Now',
      cancelLabel: 'Dismiss',
    });

    dialogRef
      .afterClosed()
      .pipe(map((res) => res?.data))
      .subscribe((res) => {
        const result = res as string;
        if (result === 'confirmed') {
          window.location.reload();
        }
      });
  }
}
