import { init, configureScope } from '@sentry/angular-ivy';
import { environment } from './environments/environment';
import packageInfo from '../package.json';

if (environment.name !== 'development') {
  init({
    dsn: 'https://9f2d6721c9bf487080a7d3f6d72a87a8@sentry.fs.eckler.ca/87',
    environment: environment.name,
    release: packageInfo.version,
  });
}

export const updateSentryScope = (
  userID: string | null,
  tenantID: string | null,
) => {
  configureScope((scope) => {
    if (userID) {
      scope.update({
        user: {
          id: userID,
        },
      });
    }
    if (tenantID) {
      scope.update({
        tags: {
          tenantID,
        },
      });
    }
  });
};
