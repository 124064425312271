import {
  faAnalytics,
  faUsersCog,
  faHome,
  faExchange,
  faUniversity,
  IconDefinition,
} from '@fortawesome/pro-solid-svg-icons';
import { UserPermission } from 'src/generated/graphql';

export interface MenuItem {
  id: string;
  name: string;
  route: string;
  icon: IconDefinition;
  include?: (p: UserPermission[]) => boolean;
}

export const menuItemsList: MenuItem[] = [
  {
    id: 'home',
    name: 'Home',
    route: 'home',
    icon: faHome,
    include: (permissions: UserPermission[]) =>
      Boolean(
        permissions.find((p) =>
          [
            UserPermission.ManagePlans,
            UserPermission.ManageRolesAndUsers,
            UserPermission.ManageSystemSettings,
          ].includes(p),
        ),
      ),
  },
  {
    id: 'manage-plans',
    name: 'Plans',
    route: 'manage-plans',
    icon: faAnalytics,
    include: (permissions: UserPermission[]) =>
      permissions.includes(UserPermission.ManagePlans),
  },
  {
    id: 'roles-and-users',
    name: 'Roles & Users',
    route: 'roles-and-users',
    icon: faUsersCog,
    include: (permissions: UserPermission[]) =>
      permissions.includes(UserPermission.ManageRolesAndUsers),
  },
  {
    id: 'manage-system',
    name: 'System Settings',
    route: 'manage-system',
    icon: faUniversity,
    include: (permissions: UserPermission[]) =>
      permissions.includes(UserPermission.ManageSystemSettings),
  },
  {
    id: 'switch-tenants',
    name: 'Switch Tenant',
    route: 'switch-tenants',
    icon: faExchange,
  },
];
