<div mat-dialog-content class="content">
  <h2 *ngIf="title" class="mat-h2 primary-typography">
    {{ title }}
  </h2>
  <ul *ngIf="itemList">
    <li *ngFor="let item of itemList">
      {{ item }}
    </li>
  </ul>
  <p
    class="mat-h4 primary-typography"
    [ngClass]="{ 'no-bottom-margin': !title }"
  >
    {{ message }}
  </p>
</div>
<div mat-dialog-actions *ngrxLet="inProgress$ as inProgress">
  <button
    mat-flat-button
    color="accent"
    [disabled]="inProgress"
    (click)="onConfirmClicked()"
  >
    {{ confirmLabel || 'CONFIRM' }}
  </button>
  <button mat-stroked-button color="accent" mat-dialog-close="">
    {{ cancelLabel || 'Cancel' }}
  </button>
  <app-spinner *ngIf="inProgress" color="accent"></app-spinner>
</div>
