import { Injectable } from '@angular/core';
import {
  confirmResetPassword,
  confirmSignIn,
  fetchAuthSession,
  getCurrentUser,
  signIn,
  signOut,
  updatePassword,
} from '@aws-amplify/auth';
import { from, switchMap, tap, map } from 'rxjs';
import { updateSentryScope } from 'src/sentry';
@Injectable({
  providedIn: 'root',
})
export class CognitoApiService {
  constructor() {
    // do nothing.
  }

  // Todo: if used more frequent then add ShareReplay() function from RxJs
  currentSession() {
    return from(fetchAuthSession());
  }

  accessToken() {
    return this.currentSession().pipe(map((s) => s.tokens?.accessToken));
  }

  authenticatedUser() {
    return from(
      Promise.all([fetchAuthSession(), getCurrentUser()]).then(
        ([session, user]) => ({ ...session, ...user }),
      ),
    );
  }

  changePassword(oldPassword: string, newPassword: string) {
    return this.authenticatedUser().pipe(
      switchMap((user) => {
        updateSentryScope(user.username, null);

        return from(updatePassword({ oldPassword, newPassword }));
      }),
    );
  }

  signOut() {
    return from(signOut());
  }

  signIn(username: string, password: string) {
    return from(signIn({ username, password })).pipe(
      tap(async (user) => {
        if (!user.isSignedIn) {
          return;
        }
        try {
          const user = await getCurrentUser();
          updateSentryScope(user.username, null);
        } catch {}
      }),
    );
  }

  completeNewPassword(password: string) {
    return from(confirmSignIn({ challengeResponse: password })).pipe(
      tap(async (resp) => {
        if (!resp.isSignedIn) return;

        try {
          const user = await getCurrentUser();
          updateSentryScope(user.username, null);
        } catch {}
      }),
    );
  }

  forgotPasswordSubmit(email: string, code: string, password: string) {
    return from(
      confirmResetPassword({
        username: email,
        confirmationCode: code,
        newPassword: password,
      }),
    );
  }
}
