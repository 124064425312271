<div class="card">
  <h1 class="mat-h1 primary-typography">Having issues?</h1>
  <h4 class="mat-h4 primary-typography">
    Check if there are any operational issues that may be impacting the
    platform:
  </h4>
  <div class="buttons" style="margin-bottom: 2em">
    <a
      mat-flat-button
      color="accent"
      href="https://status.elegantadmin.com"
      target="elegant-status"
    >
      <fa-icon [icon]="faArrowUpRightFromSquare" [fixedWidth]="true"></fa-icon>
      Status Page
    </a>
  </div>
  <h2 class="mat-h2 primary-typography">
    Not an operational issue or have a question?
  </h2>
  <h4 class="mat-h4 primary-typography">
    Send an email to our support team who will gladly assist:
  </h4>
  <div class="buttons">
    <a mat-stroked-button color="accent" href="mailto:fssupport@eckler.ca">
      <fa-icon [icon]="faEnvelope" [fixedWidth]="true"></fa-icon>
      Email Support
    </a>
  </div>
</div>
