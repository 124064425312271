<ng-template #isLoading>
  <ng-template [ngIf]="!queryError"><app-spinner></app-spinner></ng-template>
  <app-error *ngIf="queryError" [refreshCurrentPage]="true"> </app-error>
</ng-template>
<div
  class="content"
  *ngIf="permissions$ | async as permissions; else isLoading"
>
  <app-home-card
    backgroundImage="
      url(/assets/analytics-solid.svg)
    "
    backgroundColor="#49bfb8"
    textShadow="#41aaa3"
    title="PLANS"
    path="/manage-plans"
    *ngIf="permissions.includes(userPermissions.ManagePlans)"
  ></app-home-card>
  <app-home-card
    backgroundImage="
      url(/assets/users-cog-solid.svg)
    "
    backgroundColor="#d11d58"
    textShadow="#a82951"
    title="ROLES & USERS"
    path="/roles-and-users"
    *ngIf="permissions.includes(userPermissions.ManageRolesAndUsers)"
  ></app-home-card>
  <app-home-card
    *ngIf="permissions.includes(userPermissions.ManageSystemSettings)"
    backgroundImage="
      url(/assets/university-solid.svg)
    "
    backgroundColor="#063d58"
    textShadow="#22404f"
    title="SYSTEM SETTINGS"
    path="/manage-system"
  ></app-home-card>
</div>
