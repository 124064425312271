import { GraphQLError } from 'graphql';
import {
  catchError,
  combineLatest,
  first,
  from,
  map,
  Observable,
  of,
  pipe,
  switchMap,
  throwError,
} from 'rxjs';
import type { ApolloQueryResult } from '@apollo/client/core';
import { DatePipe } from '@angular/common';
import {
  animate,
  AnimationTriggerMetadata,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  faCaretDown,
  faEdit,
  faTimesCircle,
  faTrashAlt,
} from '@fortawesome/pro-solid-svg-icons';

import { faPlus, IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { PlanType } from 'src/generated/graphql';
import { MatTableDataSource } from '@angular/material/table';
import { MutationResult } from 'apollo-angular';
import { mergeMap } from 'rxjs/operators';

export const NETWORK_ERROR_MESSAGE = 'Failed to connect to the network.';

export const ERROR_MESSAGE = 'Something went wrong... Please try again!';

export const getErrorGQL = (
  error: readonly GraphQLError[] | undefined,
): string => {
  let errorMessage = '';
  error?.filter((e) => {
    if (e.message) {
      errorMessage = e.message;
    }
  });
  return errorMessage;
};

export const planTypeMapping = (value: PlanType) => {
  switch (value) {
    case PlanType.Db:
      return 'Defined Benefit (DB)';
    case PlanType.Dc:
      return 'Defined Contribution (DC)';
    case PlanType.Dcu:
      return 'Defined Contribution Unitised (DCU)';
    default:
      return value;
  }
};

const mutationResult = <T>(
  input: MutationResult<T> & {
    extensions?: Record<string, any> | undefined;
    context?: Record<string, any> | undefined;
  },
): {
  result: MutationResult<T> | null;
  error: string;
} => {
  const errors = input.errors || [];
  for (const error of errors) {
    if (error.message) {
      return { result: null, error: error.message };
    }
  }
  return { result: input, error: '' };
};

export const getMutationResult =
  <T>(passCaughtError = false) =>
  (source$: Observable<MutationResult<T>>) =>
    source$.pipe(
      first(),
      map(mutationResult),
      catchError((err) => {
        console.log(err);
        return of({
          result: null,
          error: passCaughtError ? err.message || err : NETWORK_ERROR_MESSAGE,
        });
      }),
    );
/**
 * mutationResultOrError is a function to be used in a switchMap or mergeMap to convert operational errors from the mutation
 * into error events for the observable.
 *
 * @param input
 */
const mutationResultOrError = <T>(
  input: MutationResult<T> & {
    extensions?: Record<string, any> | undefined;
    context?: Record<string, any> | undefined;
  },
): Observable<T | null | undefined> => {
  const errors = input.errors || [];
  for (const error of errors) {
    if (error.message) {
      return throwError(() => error);
    }
  }
  return of(input.data);
};
/**
 * getMutationResultOrError is an operator that will convert the errors from the mutation regardless of network or operational
 * errors into an error event on the observable so we can use native observable API to handle the error.
 *
 */
export const getMutationResultOrError =
  <T>() =>
  (source$: Observable<MutationResult<T>>) =>
    source$.pipe(
      switchMap(mutationResultOrError),
      catchError((error) =>
        throwError(() =>
          error.message.includes('0 Unknown Error')
            ? new Error(NETWORK_ERROR_MESSAGE)
            : error,
        ),
      ),
    );

const queryResult = <T>(
  input: ApolloQueryResult<T>,
): {
  result: ApolloQueryResult<T>;
  error: string;
} => {
  let errorMessage = '';

  input.errors?.filter((e) => {
    if (e.message) {
      errorMessage = e.message;
    }
  });

  return { result: input, error: errorMessage };
};

export const getQueryResult =
  <T>() =>
  (source$: Observable<ApolloQueryResult<T>>) =>
    source$.pipe(
      map(queryResult),
      catchError((err) => {
        console.log(err);
        return of({
          result: null,
          error: ERROR_MESSAGE,
        });
      }),
    );
/**
 * queryResultOrError is a function to be used in a switchMap or mergeMap to convert operational errors from the query into
 * error events for the observable.
 *
 * @param input
 */
const queryResultOrError = <T>(
  input: ApolloQueryResult<T>,
): Observable<T | null | undefined> => {
  const error = input.error || input.errors?.find((e) => e);
  if (error) {
    return throwError(() => error);
  }
  return of(input.data);
};
/**
 * getMutationResultOrError is an operator that will convert the errors from the mutation regardless of network or operational
 * errors into an error event on the observable so we can use native observable API to handle the error.
 *
 */
export const getQueryResultOrError =
  <T>() =>
  (source$: Observable<ApolloQueryResult<T>>) =>
    source$.pipe(
      switchMap(queryResultOrError),
      catchError((error) =>
        throwError(() =>
          error.message.includes('0 Unknown Error')
            ? new Error(NETWORK_ERROR_MESSAGE)
            : error,
        ),
      ),
    );

export const yesNoOptions = [
  {
    id: 'Yes',
    value: true,
  },
  {
    id: 'No',
    value: false,
  },
];

export const formatDate = (value: string | null | undefined) => {
  if (!value) {
    return null;
  }
  const datePipe = new DatePipe('en-US');
  return datePipe.transform(value, 'yyyy-MM-dd');
};

export const longDateFormat = (value: string) => {
  const datePipe = new DatePipe('en-US');
  return datePipe.transform(value, 'longDate');
};

export const rowExpandableAnimation: AnimationTriggerMetadata = trigger(
  'detailExpand',
  [
    transition(':enter', [
      style({ height: '0px', minHeight: '0' }),
      animate('200ms ease-out', style({ height: '*' })),
    ]),
    transition(':leave', [animate('200ms ease-out', style({ height: '0px' }))]),
  ],
);

interface TableIconsProps {
  faEdit: IconDefinition;
  faTrash: IconDefinition;
  faPlus: IconDefinition;
  faTimesCircle: IconDefinition;
  faDown: IconDefinition;
}

export const tableIcons: TableIconsProps = {
  faEdit,
  faTrash: faTrashAlt,
  faPlus,
  faTimesCircle,
  faDown: faCaretDown,
};

export const dateTimeFormat = (value: string) => {
  const datePipe = new DatePipe('en-US');
  const formattedDate = datePipe.transform(value, 'mediumDate');
  const formattedTime = datePipe.transform(value, 'shortTime');
  return formattedDate + ' ' + formattedTime;
};

/**
 * Creates an observable that returns table data source that has a filter
 *
 * @param data$
 * @param filter$
 * @deprecated migrate to getTableDataSource
 */
export const getLegacyTableDataSource = <D>(
  data$: Observable<D[] | null>,
  filter$: Observable<string>,
) =>
  combineLatest([data$, filter$]).pipe(
    map(([data, filterValue]) => {
      if (!data) {
        return null;
      }
      const ds = new MatTableDataSource(data);
      ds.filterPredicate = (dataSrc: any) => {
        const parsedData = JSON.stringify(dataSrc).toLowerCase();

        return parsedData.indexOf(filterValue.toLowerCase()) !== -1;
      };
      ds.filter = filterValue;
      return ds;
    }),
  );
/**
 * Creates an observable that returns table data source that has a filter
 *
 * @param data$
 * @param filter$
 */
export const getTableDataSource = <D>(
  data$: Observable<D[] | null>,
  filter$: Observable<string>,
) =>
  combineLatest([data$, filter$]).pipe(
    map(([data, filterValue]) => {
      if (!data) {
        return null;
      }
      const ds = new MatTableDataSource(data);
      ds.filterPredicate = (dataSrc: any) => {
        const parsedData = JSON.stringify(dataSrc).toLowerCase();

        return parsedData.indexOf(filterValue.toLowerCase()) !== -1;
      };
      ds.filter = filterValue;
      return ds;
    }),
  );

/**
 * This function will convert a YYYY-MM-DD string into an ISO
 * string so that when passed to a date picker it will select the correct
 * date without concerns of time zones.
 *
 * @param value
 * @returns
 */
export const dateToLocalISO = (value: string) => {
  // Already most likely an ISO string
  if (value.includes('T')) {
    return value;
  }
  const parts = value.split('-');
  if (parts.length !== 3) {
    throw new Error(`${value} is not a valid YYYY-MM-DD string`);
  }

  const year = Number(parts[0]);
  const month = Number(parts[1]);
  const day = Number(parts[2]);

  const date = new Date(year, month - 1, day);
  return date.toISOString();
};

export const parseCustomFieldValues = (values: string) =>
  (Object.entries(JSON.parse(values)) as Array<[string, string]>)
    .filter(([, value]) => Boolean(value))
    .map(([fieldID, value]) => ({
      fieldID,
      value,
    }));

export const formatCustomFieldValues = (
  customFieldValueMap?: Map<string, string>,
) =>
  customFieldValueMap
    ? JSON.stringify(Object.fromEntries(customFieldValueMap))
    : '{}';

const extractFilename = (contentDisposition: string | null) => {
  if (!contentDisposition) {
    return null;
  }
  const pairs = new Map<string, string>();
  const parts = contentDisposition.split(';');
  for (const part of parts) {
    const pair = part.trim().split('=');
    pairs.set(pair[0], pair[1]);
  }
  return pairs.get('filename')?.replaceAll('"', '');
};

/**
 * Utility operator to easily compose the logic of extracting the blob from the response
 */
export const receiveBlob = () =>
  pipe(
    switchMap((response: Response) => {
      if (!response.ok) {
        return from(response.text()).pipe(
          mergeMap((err) =>
            throwError(
              () =>
                new Error(err || 'unexpected status code ' + response.status),
            ),
          ),
        );
      }
      const filename = extractFilename(
        response.headers.get('content-disposition'),
      );
      return from(response.blob()).pipe(map((blob) => ({ filename, blob })));
    }),
  );
